/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.main-header {
  text-align: center;
}
.consent-group {
  display: grid;
}
.background {
  background: white;
}
.mobile .background {
  padding: 12px 16px;
}
.page-loading {
  min-height: 300px;
}
.page-loading .ant-spin-blur {
  opacity: 0.4;
}
.page-loading .ant-spin-dot i {
  background-color: #1890ff;
}
.page-loading .ant-spin-text {
  font-size: 2em;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  margin-top: 14px;
  color: #1890ff;
}
.consent-form-header {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
}
.consent-form-title {
  text-align: center;
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: #14837B;
}
.service-detail-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
}
.service-detail-container .service-col {
  flex-basis: 50%;
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
  border: 1px solid #e8e8e8;
}
.service-detail-container .service-col > label {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #14837B;
  min-width: 100px;
  flex-basis: 100px;
  background-color: #f5f5f5;
  padding: 4px 8px;
}
.service-detail-container .service-col > .service-val {
  flex-grow: 1;
  padding: 4px 8px;
  min-width: 0;
}
.service-detail-container .service-col > .service-val .service-val-row {
  margin-bottom: 2px;
}
.service-detail-container .service-col > .service-val .service-val-row:last-child {
  margin-bottom: 0;
}
.total-section {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 0px;
  margin-bottom: 16px;
}
.total-section .total-section-column {
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  margin-top: 8px;
  margin-bottom: 0px;
}
.total-section .total-section-column .total-section-row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  align-items: right;
  margin-top: 8px;
}
.total-section .total-section-column .total-section-row .total-section-description {
  display: flex;
  flex-basis: 50%;
  position: relative;
  justify-content: right;
  align-items: right;
  margin-right: 8px;
}
.total-section .total-section-column .total-section-row .total-section-dash {
  display: flex;
  flex-basis: 2%;
  position: relative;
  justify-content: right;
  align-items: right;
  margin-right: 8px;
}
.total-section .total-section-column .total-section-row .total-section-price-claim {
  display: flex;
  flex-basis: 20%;
  position: relative;
  justify-content: right;
  align-items: right;
  margin-right: 8px;
}
.total-section .total-section-column .total-section-row .total-section-price {
  display: flex;
  flex-basis: 20%;
  position: relative;
  justify-content: left;
  align-items: left;
  margin-right: 8px;
}
.total-section .total-section-column .total-section-row .total-section-unit {
  display: flex;
  flex-basis: 8%;
  position: relative;
  justify-content: left;
  align-items: left;
  margin-right: 8px;
}
.sign-area {
  display: block;
  align-items: center;
  width: 123.16px;
  height: 40px;
}
.signature-consent {
  margin-left: auto;
  text-align: center;
  display: block;
  width: 30%;
}
.signature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.signature .signature-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.signature .sign-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signature-img {
  display: block;
  width: 100%;
}
.page-divider {
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px dashed #14837B;
  position: relative;
}
.page-action-left {
  float: left;
  margin-bottom: 8px;
}
.page-action-right {
  float: right;
  margin-bottom: 8px;
}
